html {
    --content-wrap: 1140px;
    --content-wrap-wide: 1400px; 
    --headerHeight: 60px;
    --footerHeight: 120px;

    --gutter: 16px;
    --gutter-double: 32px;
    --gutter-quadrouple: 64px;
    --gutter-half: 8px;
    --gutter-quarter: 4px;

    --color-primary: #D3D5D4;
    --color-secondary: #A2C5AC;

    --color-tertiary: #9DB5B2;
    --color-background: #9DB5B2;
    --color-background-light: #f5f5f5;
    --color-text-on-background: white;

    --color-link: #FE6B8B;

    --font-small: 10px;
    --font-med: 16px;
    --font-large: 28px;
    --font-mega: 40px;

    --color-border: #e1e1e1;

    --fadeOpacity: 0.25;

    --gradient: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);

    --avatar-size-large: 128px;

    --border-radius: 4px;

    --box-shadow: 0px 1px 7px rgba(0,0,0,0.1);
}