@import "Vars.css";

body, html {
  background: var(--color-background-light);
}
.App {
  text-align: center;
  background: var(--color-background-light);
  width: 100%;
}

.content-wrap {
  max-width: var(--content-wrap);
  margin: auto;
  width: 100%;
}

header {
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.main-logo {
  width: 230px;
  display: inline-block;
  text-align: left;
}
.logo {
  display: inline-block;
  width: 230px;
  vertical-align: middle;
  text-align: left;
}
.logo img {
  display: inline-block;
  vertical-align: bottom;
}
.logo h1 {
  display: inline-block;
  vertical-align: bottom;
  width: 130px;
  font-size: 22px !important;
  text-align: left;
  font-weight: 600;
  line-height: 0.9;
}

.main-content {
  background: var(--color-background-light);
  margin: var(--gutter-double) var(--gutter-half);
}

footer {
  background: var(--color-background-light);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  color: var(--color-text);
  padding: var(--gutter-double);
  text-align: left;
  border-top: 1px solid var(--color-border);
}

.App-link, a {
  color: var(--color-link);
}

.icon-wrap {
  text-align: center;
  border: 1px solid #e1e1e1;
  background: var(--color-background);
  color: var(--color-background-light);
  padding: var(--gutter);
  min-height: 180px;
}
.icon-wrap strong {
  display: block;
  font-size: var(--font-large) !important;
}
.icon-wrap p {
  padding: 0;
  margin: 0;
  font-weight: 500;
  line-height: 1.35;
}
.icon-large {
  margin: var(--gutter);
  font-size: var(--font-mega) !important;
}
.icon-mega {
  margin: var(--gutter);
  font-size: 66px !important;
}
.icon-wrap.main {
  background: var(--red);
}
.font-small {
  font-size: var(--font-small);
}
h1, h2, h3, h4 {
  margin: 0 0 var(--gutter) 0 !important;
  padding: 0 !important;
  font-size: var(--font-large) !important;
  letter-spacing: -1px !important;
  font-weight: 500 !important;
  display: block;
  width: 100%;
}

p {
  margin: var(--gutter) 0;
}

form fieldset {
  margin-bottom: var(--gutter);
}
form label {
  margin-right: var(--gutter);
  width: 40%;
}

input, label, select {
  vertical-align: top;
}

input, select {
  padding: var(--gutter-half);
}

input[type="checkbox"] {
  width: 46px;
  height: 24px;
  display: inline-block;
  vertical-align: bottom;
  vertical-align: baseline;
}

.checkboxes {
  padding: 16px 0;
}
.checkboxes label {
  width: 66%;
}

form input {
  width: 55%;
}

textarea {
  width: 55%;
  min-height: 180px;
}

.content-block {
  width: 100%;
  margin: var(--gutter) 0;
}

.sidebar-wrap {
  background: #e1e1e1;
    padding: 16px;
    border-radius: 8px;
}

.xs-only {
  display: none;
}

.heart {
  font-size: 16px;
  color: red;
  vertical-align: top;
}

.share {
  text-align: center;
  margin-right: var(--gutter-half);
  margin-top: -10px;
}
.share button {
  margin: var(--gutter);
  margin-right: 0;
}
.share svg {
  margin-right: var(--gutter-half);
}
.share strong {
  vertical-align: middle;
}

@media (min-width: 992px) {
div.container {
    max-width: 1120px;
}
}
@media (max-width: 700px) {
form.form label {
  display: block;
}
}

ul li {
  margin: var(--gutter-half) 0;
}

.disclaimer {
  background: var(--warning);
  padding: var(--gutter);
}

.disclaimer p {
  margin: 0;
  padding: 0;
}

.code {
  background: #f5f5f5;
  font-family: monospace;
  margin-left: var(--gutter-double);
}

.ReactModal__Content {
  width: 440px;
  height: 640px;
  position: absolute;
  top: 100px;
  text-align: center;
  z-index: 999;
}

@media (max-width: 992px) {
  .ReactModal__Content {
    width: 400px;
    height: 640px;
    position: absolute;
    top: 14s0px;
    text-align: center;
    z-index: 999;
    inset: 20px !important;
    position: fixed !important;
    width: 75% !important;
  }
}
.ReactModal__Overlay {
  background: rgba(0,0,0,0.8) !important;
}

.map-filters {
  border: 8px solid #f5f5f5;
  padding-top: var(--gutter-half);
}
.map-btn-mps {
  margin-right: var(--gutter);
  margin-bottom: var(--gutter);
}
.map-btn-mps:last-child {
  margin-right: 0;
}

.nhs-button {
  padding: var(--gutter);
}

.btn.lang-btn {
  font-size: 34px;
  padding: 4px;
  background: none;
  border: none;
  margin-left: var(--gutter-quarter);
}

.btn.lang-btn:hover {
  background: #e1e1e1;
}

.warning {
  border:1px solid var(--danger);
  color: var(--danger);
  border-radius: 8px;
  padding: var(--gutter);
  background: rgba(220, 53, 69, 0.2);
  text-align: center;
}
.languages {
  display: inline-block;
}

form.form label {
  padding-right: var(--gutter);

}

form input, form select, form textarea {
  border-radius: var(--gutter-half);
  border: 2px solid #e1e1e1;
}

#btn-add-building {
  margin: 0px 16px 0 0px;
  padding: 16px;
  font-weight: bold;
  font-size: 20px;
  background: #fd7e14;
  border: 0;
}

.bsclogo {
  margin-left: 24px;
}

@media (max-width: 992px) {
  .xs-only {
    display: block;
  }
  .main-content {
      margin: 0;
      margin-top: var(--gutter);
  }
  div.share {
    font-size: 0;
    text-align: left;
    margin-bottom: -20px;
  }

  div.share a:first-child {
    margin: var(--gutter);
    display: inline-block;
    width: 170px;
    margin-left: 0;
  }
  div.share a button {
    margin: 0 var(--gutter);
    display: inline-block;
    width: 90px;
    font-size: 16px !important;
    position: absolute;
    right: 30px;
    top: 40px;
    padding: 16px 0 !important;
  }

  .share strong {
    font-size: var(--font-med);
  }

  .main-logo {
    margin: auto;
    padding: var(--gutter);
    text-align: center;
    display: block;
  }
  #btn-add-building {
    margin: 23px 70px -20px 0;
    padding: 8px 0px !important;
    line-height: 1em;
    font-size: 12px !important;
    width: 70px;
  }
  .bsclogo {
    position: absolute;
    top: 60px;
    right: 16px;
  }
  .logo h1 {
    font-size: 17px !important;
    margin-top: -16px;
    font-size: 17px !important;
  }
  .bsclogo img {
    height: 50px;
  }
  .share-wrap {
    display: none;
  }
  .map-filters {
    padding-bottom: 26px;
  }
  .map-filters button {
    margin: 4px 0;
  }

  .languages {
    display: none;
  }
}

#map-date {
  text-align: left;
  position: absolute;
  margin-top: -850px;
  background: rgba(0,0,0,0.5);
  padding: 8px;
  color: white;
  font-weight: bold;
  line-height: 2em;
      right: 77px;
}